import * as s from './styles'
import LocalizedLink from '@components/Localization/LocalizedLink'
import { NewAtGassan } from '@components/NewAtGassan'
import { Button } from '@components/gassan-ui'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

export const CreateAccountEnterEmail: FC = () => {
  const { t } = useTranslation(['account', 'other', 'navigation'])

  return (
    <div className={s.parent}>
      <NewAtGassan />
      <LocalizedLink href={t('account.create.url', { ns: 'navigation' })}>
        <Button as="link" variant="dark">
          {t('becomeMember', { ns: 'account' })}
        </Button>
      </LocalizedLink>
    </div>
  )
}
