// @ts-strict-ignore
import { FloatingServiceMenu, Footer, Header, Page, PageTransition } from '@components/Page'
import { LoginLoginSection } from '@components/_account/_login/LoginForm'
import { CreateAccountEnterEmail } from '@components/_account/_login/create-account-enter-email'
import { LoginPage } from '@components/_login'
import { buildLanguageAlternateNavigation } from '@lib/build-language-alternates'
import { getServerSideTranslations } from '@lib/get-server-side-translations'
import { useAuth } from '@lib/hooks/use-auth'
import { GetStaticProps } from 'next'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC, useEffect } from 'react'

const Login: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('navigation')
  const { isLoggedIn } = useAuth()
  const { push, query, locales } = useRouter()
  const isPasswordReset = query['pw-reset'] !== undefined

  useEffect(() => {
    if (isLoggedIn) {
      const redirectAfterLogin = window.sessionStorage.getItem('redirectAfterLogin')
      if (redirectAfterLogin) {
        window.sessionStorage.removeItem('redirectAfterLogin')
        push(redirectAfterLogin)
      } else {
        push(`/${language}${t('membership.overview.url')}`)
      }
    }
  }, [isLoggedIn]) // eslint-disable-line

  return (
    <Page
      languageAlternates={buildLanguageAlternateNavigation(locales, 'account.login.url')}
      title="Login"
    >
      <FloatingServiceMenu />
      <Header initialVariant="absolute" />
      <PageTransition>
        <LoginPage>
          <LoginLoginSection isPasswordReset={isPasswordReset} />
          <CreateAccountEnterEmail />
        </LoginPage>
        <Footer />
      </PageTransition>
    </Page>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await getServerSideTranslations(locale)),
    },
  }
}

export default Login
