import { FC } from 'react'

export const SocialLoginFacebookIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <g clipPath="url(#clip0_10753_111091)">
        <path fill="#1877F2" d="M0 0H24V24H0z"></path>
        <path
          fill="#fff"
          d="M23.5 12.07C23.5 5.719 18.351.57 12 .57S.5 5.719.5 12.07c0 5.74 4.205 10.497 9.703 11.36v-8.036h-2.92V12.07h2.92V9.536c0-2.882 1.717-4.474 4.344-4.474 1.258 0 2.574.225 2.574.225v2.83h-1.45c-1.429 0-1.874.886-1.874 1.796v2.157h3.19l-.51 3.324h-2.68v8.036c5.498-.863 9.703-5.62 9.703-11.36z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_10753_111091">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
