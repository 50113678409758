import { SocialLoginAppleIcon } from './AppleIcon'
import { SocialLoginButton } from './Button'
import { SocialLoginFacebookIcon } from './FacebookIcon'
import { SocialLoginGoogleIcon } from './GoogleIcon'
import { SocialLoginLinkedInIcon } from './LinkedInIcon'
import { Spinner, OldText } from '@gassan-ui'
import { useCdcSocialLogin } from '@lib/cdc/use-cdc-social-login'
import { cn } from '@lib/cn'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC } from 'react'

export const SocialLogin: FC = () => {
  const { t } = useTranslation('navigation')
  const { push } = useRouter()
  const { login, status, error } = useCdcSocialLogin({
    handleAccountNotFound: () => {
      push(t('account.completeSocialSignup.url'))
    },
  })

  return (
    <div
      className={cn(
        'relative mb-6 grid w-full grid-cols-2 gap-2 border-b border-solid border-pampasLight pb-6 transition-all duration-150 md:mb-8 md:gap-4 md:pb-8 lg:mb-10 lg:pb-10',
        status === 'loading' && 'pointer-events-none opacity-50',
      )}
    >
      <SocialLoginButton onClick={() => login('apple')} className="bg-black">
        <SocialLoginAppleIcon />
        <OldText variant="regular" color="inherit" mb="0">
          Apple
        </OldText>
      </SocialLoginButton>
      <SocialLoginButton onClick={() => login('facebook')} className="bg-[#1877f2]">
        <SocialLoginFacebookIcon />
        <OldText variant="regular" color="inherit" mb="0">
          Facebook
        </OldText>
      </SocialLoginButton>
      <SocialLoginButton
        onClick={() => login('googleplus')}
        className="border border-solid border-shade-300 bg-white text-bigStone"
      >
        <SocialLoginGoogleIcon />
        <OldText variant="regular" color="inherit" mb="0">
          Google
        </OldText>
      </SocialLoginButton>
      <SocialLoginButton onClick={() => login('linkedin')} className="bg-[#0274b3]">
        <SocialLoginLinkedInIcon />
        <OldText variant="regular" color="inherit" mb="0">
          LinkedIn
        </OldText>
      </SocialLoginButton>
      {status === 'loading' && (
        <div className="absolute -top-3 flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      )}
      {error && (
        <OldText variant="micro" color="error" mb="0" className="absolute bottom-3 left-0 w-full">
          {error}
        </OldText>
      )}
    </div>
  )
}
