import { SocialLogin } from './social-login';
import { Heading } from '@components/gassan-ui/Typography/Heading';
import InlineNotification from '@components/inline-notification';
import { LoginForm } from '@components/login-form';
import { parse } from '@config/theme';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
type LoginLoginSectionProps = {
  isPasswordReset: boolean;
};
export const LoginLoginSection: FC<LoginLoginSectionProps> = ({
  isPasswordReset
}) => {
  const {
    t
  } = useTranslation('other');
  return <div className={parent}>
      <Heading variant="h3" as="h3">
        {t('logIntoAccount')}
      </Heading>
      <AnimatePresence>
        {isPasswordReset && <InlineNotification variant="success" title={t('passwordResetLogIn')} className="mb-6 md:mb-8" />}
      </AnimatePresence>
      <div>
        <SocialLogin />
        <LoginForm />
      </div>
    </div>;
};
const parent = parse({
  pb: {
    _: '8',
    small: '12',
    large: '0'
  }
}, "pqidfxq");

require("./LoginForm.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./LoginForm.tsx");