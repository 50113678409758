import { cn } from '@lib/cn'
import React, { FC } from 'react'

type SocialLoginButtonProps = {
  children: React.ReactNode
  className?: string
  onClick: () => void
}

export const SocialLoginButton: FC<SocialLoginButtonProps> = ({ onClick, children, className }) => {
  return (
    <button
      className={cn(
        'grid w-full grid-cols-[auto_1fr] items-center gap-2 p-4 text-left text-white md:gap-4',
        className,
      )}
      data-provider="google"
      onClick={onClick}
    >
      {children}
    </button>
  )
}
