import { Icon } from '@components/gassan-ui'
import { cn } from '@lib/cn'
import { motion } from 'framer-motion'
import { FC } from 'react'

type Props = {
  variant?: 'default' | 'error' | 'success'
  title: string
  onAnimationEnd?: () => void
  className?: string
}

const InlineNotification: FC<Props> = ({
  variant = 'default',
  title,
  onAnimationEnd,
  className = '',
}) => {
  return (
    <motion.div
      className={cn(
        'relative flex items-start justify-between gap-3 px-4 py-5 md:gap-4',
        'text-sm',
        'md:text-base',
        variant === 'default' && 'bg-pampas text-tabaccoBrown',
        variant === 'error' && 'bg-error/10 text-error',
        variant === 'success' && 'bg-success/10 text-success',
        className,
      )}
      onAnimationEnd={onAnimationEnd}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ easing: 'easeOut', duration: 0.2 }}
    >
      {onAnimationEnd && (
        <div className="absolute left-0 right-0 top-0 h-0.5 bg-tabaccoLight">
          <div className="absolute left-0 right-0 top-0 h-0.5 origin-left animate-fill-horizontally-slow bg-tabaccoBrown"></div>
        </div>
      )}
      <div
        className={cn(
          'flex h-8 w-8 items-center justify-center rounded-sm',
          'md:h-12 md:w-12',
          variant === 'default' && 'bg-tabaccoLight text-tabaccoBrown',
          variant === 'error' && 'bg-error/20 text-error',
          variant === 'success' && 'bg-success/20 text-success',
        )}
        data-variant={variant}
      >
        <Icon icon={variant === 'error' ? 'exclamation' : 'check'} />
      </div>
      <div className="flex-1">
        <div>
          <p className="mt-3 font-bold">{title}</p>
        </div>
      </div>
    </motion.div>
  )
}

export default InlineNotification
