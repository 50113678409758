import { FC } from 'react'

export const SocialLoginLinkedInIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#fff"
        d="M20.25 3H3.75C3.3 3 3 3.3 3 3.75v16.5c0 .45.3.75.75.75h16.5c.45 0 .75-.3.75-.75V3.75c0-.45-.3-.75-.75-.75zM8.325 18.375H5.7V9.75h2.7v8.625h-.075zM6.975 8.55A1.584 1.584 0 015.4 6.975c0-.825.675-1.575 1.575-1.575.825 0 1.575.675 1.575 1.575 0 .9-.675 1.575-1.575 1.575zm11.4 9.825h-2.7v-4.2c0-.975 0-2.25-1.35-2.25-1.425 0-1.575 1.05-1.575 2.175v4.275h-2.7V9.75h2.55v1.2c.375-.675 1.2-1.35 2.55-1.35 2.7 0 3.225 1.8 3.225 4.125v4.65z"
      ></path>
    </svg>
  )
}
