import { useCartReferenceStore } from '@components/LoadCart'
import { useLocalization } from '@components/Localization'
import { Button, Field, Link, Text } from '@components/gassan-ui'
import InlineNotification from '@components/inline-notification'
import { useCdcLoginMutation } from '@generated'
import { emailRequired } from '@lib/yup'
import { Formik, Form as FormikForm, FormikProps } from 'formik'
import { useTranslation } from 'next-i18next'
import { FC, useState } from 'react'
import * as Yup from 'yup'
import * as styles from './Form.styles'

const loginSchema = Yup.object().shape({
  email: emailRequired,
  password: Yup.string().required('forms.errors.isRequired'),
})

type FormValues = {
  email: string
  password: string
}

type FormProps = {
  handleForgotPassword: () => void
  handleLoginSuccess: () => void
}

// Gigya error codes for not verified accounts
// https://help.sap.com/docs/SAP_CUSTOMER_DATA_CLOUD/8b8d6fffe113457094a17701f63e3d6a/416d41b170b21014bbc5a10ce4041860.html
const gigyaNotVerifiedErrorCodes = ['206001', '206002']

export const Form: FC<FormProps> = ({ handleForgotPassword, handleLoginSuccess }) => {
  const { parseGraphQlError } = useLocalization()
  const { t } = useTranslation(['forms', 'other', 'navigation'])
  const [, cdcLogin] = useCdcLoginMutation()
  const [error, setError] = useState<string | null>(null)
  const { reference } = useCartReferenceStore()

  return (
    <>
      <Formik
        validateOnChange
        validateOnBlur
        validateOnMount={false}
        validationSchema={loginSchema}
        initialValues={{ email: '', password: '' }}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)
          setError(null)
          const { data, error } = await cdcLogin({
            input: {
              email: values.email.toLowerCase(),
              password: values.password,
              cartReference: reference,
            },
          })
          if (error) {
            const errorCode = error.graphQLErrors[0]?.extensions?.errorCode
            if (
              errorCode &&
              gigyaNotVerifiedErrorCodes.some((code) => errorCode.toString().includes(code))
            ) {
              setError(errorCode.toString())
            } else {
              setError(parseGraphQlError(error.message))
            }
          }
          if (data && data?.cdcLogin) {
            handleLoginSuccess()
          }
          setSubmitting(false)
        }}
      >
        {({ isValid, isSubmitting, values }: FormikProps<FormValues>) => {
          return (
            <>
              {!isSubmitting && error && (
                <>
                  {gigyaNotVerifiedErrorCodes.includes(error) ? (
                    <div className="mb-6 bg-pampas p-4 md:mb-8">
                      <Text variant="regular">{t('emailNotVerified', { ns: 'other' })}</Text>
                      <Link
                        variant="brown"
                        href={t('account.requestActivationLink.url', { ns: 'navigation' })}
                      >
                        {t('account.requestActivationLink.title', { ns: 'navigation' })}
                      </Link>
                    </div>
                  ) : (
                    <InlineNotification variant="error" title={error} className="mb-6 md:mb-8" />
                  )}
                </>
              )}
              <FormikForm>
                <Field
                  field="input"
                  name="email"
                  autoComplete="username"
                  id="login-email"
                  value={values.email.toLowerCase()}
                  label={t('labels.email', { ns: 'forms' })}
                />
                <Field
                  type="password"
                  field="input"
                  autoComplete="current-password"
                  name="password"
                  label={t('labels.password', { ns: 'forms' })}
                />
                <div className={styles.buttons}>
                  <Button
                    id="login-button"
                    type="submit"
                    variant="dark"
                    status={isSubmitting ? 'loading' : isValid ? 'idle' : 'disabled'}
                    className="mb-4 w-full sm:mb-0 sm:w-auto"
                  >
                    {t('login', { ns: 'other' })}
                  </Button>
                  <Link variant="brown" type="button" as="button" onClick={handleForgotPassword}>
                    {t('forgotPassword', { ns: 'other' })}
                  </Link>
                </div>
              </FormikForm>
            </>
          )
        }}
      </Formik>
    </>
  )
}
