import { Form } from './Form'
import { ForgotPasswordModal } from './forgot-password-modal'
import { useAuth } from '@lib/hooks/use-auth'
import { FC, useState } from 'react'

type LoginFormProps = {
  onLoginSuccess?: () => void
}

export const LoginForm: FC<LoginFormProps> = ({ onLoginSuccess }) => {
  const [showModal, setShowModal] = useState(false)
  const { login } = useAuth()

  return (
    <>
      <Form
        handleLoginSuccess={() => handleLoginSuccess()}
        handleForgotPassword={() => setShowModal(true)}
      />
      {showModal && <ForgotPasswordModal handleRequestClose={() => setShowModal(false)} />}
    </>
  )

  async function handleLoginSuccess() {
    // Set isLoggedIn to true in Zustand useAuth store
    // and fetch user data from API
    login()

    if (onLoginSuccess) {
      onLoginSuccess()
    }
  }
}
