import { useGigya } from './use-gigya'
import { useCartReferenceStore } from '@components/LoadCart'
import { useLocalization } from '@components/Localization'
import { useCdcCompleteSocialLoginMutation } from '@generated'
import { useAuth } from '@lib/hooks/use-auth'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'

type State = {
  status: 'idle' | 'loading' | 'error'
  error?: string
}

type UseCdcSocialLoginProps = {
  handleSuccessfulLogin?: () => void
  handleAccountNotFound?: () => void
}

export function useCdcSocialLogin({
  handleSuccessfulLogin,
  handleAccountNotFound,
}: UseCdcSocialLoginProps = {}) {
  const [, completeSocialLoginMutation] = useCdcCompleteSocialLoginMutation()
  const router = useRouter()
  const { reference } = useCartReferenceStore()
  const { parseGraphQlError } = useLocalization()
  const { t } = useTranslation('navigation')
  const { isReady } = useGigya()
  const auth = useAuth()
  const [state, setState] = useState<State>({
    status: 'idle',
    error: undefined,
  })

  const callback = useCallback(
    async (response: GigyaSocialLoginResponse) => {
      // Prefetch account page
      router.prefetch(t('account.overview.url'))

      // Handle error message
      if (response.errorMessage && response.errorMessage !== '') {
        setState({ status: 'error', error: parseGraphQlError(response.errorMessage) })
        return
      }

      try {
        // Post part of the response to the server, to validate the UID & Signature & set the correct cookies
        const { data, error } = await completeSocialLoginMutation({
          input: {
            UID: response.UID,
            UIDSignature: response.UIDSignature,
            signatureTimestamp: response.signatureTimestamp,
            cartReference: reference,
          },
        })

        if (data && data.cdcCompleteSocialLogin) {
          // Make sure `me` is populated in the useAuth hook
          // other auth components rely on this value to be set
          auth.login()

          setState({ status: 'idle', error: undefined })
          // If a custom callback is provided, call it
          // else redirect to the account page
          if (handleSuccessfulLogin) {
            handleSuccessfulLogin()
          } else {
            router.push(t('account.overview.url'))
          }
        }

        if (error) {
          // Forward the correct error message to the user
          if (error.message) {
            // Custom error handling for when CDC fails to recognize the user
            if (
              (error.message.includes('user-not-found') ||
                error.message.includes(`Gigya Error: Unknown user`)) &&
              handleAccountNotFound
            ) {
              handleAccountNotFound()
            } else {
              setState({ status: 'error', error: parseGraphQlError(error.message) })
            }
          } else if (error instanceof Error) {
            setState({ status: 'error', error: error.toString() })
          } else {
            setState({ status: 'error', error: 'Something went wrong' })
          }
        }
      } catch (error) {
        console.info(error)
        setState({ status: 'error', error: 'Something went wrong' })
      }
    },
    [completeSocialLoginMutation, router, parseGraphQlError, handleSuccessfulLogin, t], // eslint-disable-line
  )

  const login = useCallback(
    (provider: GigyaSocialProvider) => {
      // This validation is also done in the useGigya hook
      if (!isReady || !window.gigya) return

      setState({ error: undefined, status: 'loading' })
      // Start the login sequence
      window.gigya.accounts.socialLogin({
        provider,
        authFlow: 'popup',
        callback,
      })
    },
    [isReady, callback],
  )

  return {
    login,
    ...state,
  }
}
