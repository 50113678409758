import * as style from './styles'
import { Modal } from '@components/Modal'
import { Text } from '@components/gassan-ui/Typography/Text'
import { GigyaScreenSet } from '@components/gigya/GigyaScreenSet'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

type ForgotPasswordProps = {
  handleRequestClose: () => void
}

export const ForgotPasswordModal: FC<ForgotPasswordProps> = ({ handleRequestClose }) => {
  const { t } = useTranslation('account')

  return (
    <Modal
      title={t('requestPasswordResetTitle')}
      defaultOpen
      onOpenChange={(open) => {
        if (!open) {
          handleRequestClose()
        }
      }}
    >
      <Text className={style.text}>{t('requestPasswordResetSubtitle')}</Text>
      <GigyaScreenSet
        screenSet="Gassan-RegistrationLogin"
        startScreen="gigya-forgot-password-screen"
      />
    </Modal>
  )
}
