import { FC } from 'react'

export const SocialLoginAppleIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path fill="#000" d="M0 0H24V24H0z"></path>
      <path
        fill="#fff"
        d="M21.28 18.424c-.347.803-.759 1.543-1.236 2.223-.65.927-1.182 1.569-1.593 1.925-.636.585-1.317.885-2.047.902-.524 0-1.155-.15-1.891-.452-.738-.3-1.416-.45-2.036-.45-.65 0-1.347.15-2.093.45-.747.303-1.35.46-1.81.476-.7.03-1.397-.278-2.093-.926-.444-.387-1-1.052-1.666-1.993-.714-1.005-1.302-2.17-1.762-3.5-.492-1.435-.74-2.825-.74-4.17 0-1.542.334-2.871 1-3.985a5.867 5.867 0 012.095-2.12 5.635 5.635 0 012.832-.798c.556 0 1.285.172 2.191.51.903.339 1.483.51 1.737.51.19 0 .835-.2 1.927-.601 1.033-.372 1.904-.526 2.619-.465 1.935.156 3.388.919 4.355 2.293-1.73 1.049-2.586 2.517-2.57 4.401.016 1.468.549 2.69 1.595 3.659.474.45 1.004.798 1.593 1.045-.128.37-.263.725-.406 1.066zM16.844.96c0 1.15-.42 2.224-1.258 3.218-1.01 1.182-2.234 1.865-3.56 1.757A3.58 3.58 0 0112 5.5c0-1.105.48-2.287 1.334-3.253.426-.489.969-.896 1.626-1.22.656-.32 1.276-.497 1.86-.527.017.154.024.308.024.46z"
      ></path>
    </svg>
  )
}
